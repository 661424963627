
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');


*{
    font-family: 'Open Sans', sans-serif;
    -webkit-font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    background-color: #EAE8DA;

}
nav{
    z-index: 1000;
}
.inputs{
    display: grid;
    
}
.menu-bars {
    margin-top: 1rem;
    margin-right: 1rem;
    background: none;
    position: absolute;
}
.icon{
    width: 30px;
    height: 30px;
    transition: 200ms;
}

.icon:hover{
    transition: 100ms;
    width: 33px;
    height: 33px;
    margin-top: -.1rem;
    margin-right: -.1rem;
}

@media screen and (min-width: 1000px){
    .nav-menu {
    background-color: #060b26;
    width: 200px;
    height: 100vh;
    display: block;
    justify-content: center;
    position: fixed;
    transition: 550ms;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    top: 0;
    bottom: 0;
    }
    .nav-menu.active::-webkit-scrollbar{
        display: none;
    }
    .nav-menu.active {
    background-color: #060b26;
    width: 200px;
    height: 100vh;
    display: block;
    justify-content: center;
    position: fixed;
    transition: 550ms;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    top: 0;
    bottom: 0;
    }
    
    .nav-menu::-webkit-scrollbar{
        display: none;
    }
}



.bars-icon{
    color: #060b26;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 0px 0px 12px;
    list-style: none;
    height: 36px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: 200ms;
}

.nav-text a:hover {
    color: rgb(248, 248, 80);
    font-weight: bolder;
   
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
}

span {
    margin-left: 16px;
}

.navLabels {
    color: rgb(255, 255, 255);
    border: solid 1px white;
    border-bottom: solid 5px white;
    border-right: solid 3px white;
    display: grid;
    margin: 0.3rem 1rem .3rem 1rem;
    padding: 0rem 10px;
    line-height: 2.5rem;
}

.navLabels h3{
    font-size: 18px;
    color: rgb(236, 236, 236);
    font-weight: 300;
}

.coingecko{
    display: flex;
    color: white;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 100vh;
    bottom: 10px;
    background-color: #060b26;
    -webkit-appearance: none;
    overflow-y: hidden;
}

.coingecko h3{
    font-weight: lighter;
}

.aboutme {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0rem 0rem 0rem;
}

.iconbutton{
    width: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    border: solid 1px black;
    border-radius: 0;
    height: 35px;
    width: 166px;
    font-weight: bold;
    padding-left: .5rem;
    color: rgb(58, 58, 58);
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
input[type=number]:focus{
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;

}
.hidden-search-neighbor{
    display: none;
}
.calc-body{
    padding: 5vh 10px 5vh 205px;
}
.nav-header{
    display: none;
}
.info-hidden{
    display: none;
}
.contents{
    display: grid;
    grid-template-columns: 1fr 3fr;
}

@media screen and (max-width: 999px) {
    .nav-menu {
        background-color: #060b26;
        opacity: 0.9;
        width: 100vw;
        height: 50px;
        display: block;
        justify-content: center;
        position: fixed;
        transition: 350ms;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        
    }
    
    .nav-menu-items {
        width: 100%;
        padding-top: .5rem;
    }
    .coingecko{
        position: relative;
        top: 0;
        bottom: 0;
    }
    .nav-menu.active{
        transition: 550ms;
        height: 100vh;
       
    }
    .nav-menu::-webkit-scrollbar{
        display: none;
        overflow: scroll;
    }
    
    .calc-body{
        padding: 67px 20px 5vh 20px;
    }
    .nav-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
        padding-left: 1.2rem;
        background-color: #060b26;
        height: 50px;
        z-index: 2000;
        color: white;
    }
    ul{
        margin-top: 50px;
       
    }
    .navLabels{
        justify-content: center;
        text-align: center;
        padding: 0 0 0 12px;
    }
    .nav-text{
        justify-content: center;
        text-align: center;
        padding: 0;
    }
    .nav-text a{
        justify-content: center;
        
        padding: 0;
    }
}
.outputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.outputs-hidden{
    display: none;
}
.title{
    display: none
}
@media screen and (max-width: 768px){
    .info-box{
        display: none;
    }
    .outputs{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .info-hidden{
        display: flex;

    }
    .select-search{
        max-width: 159px;
        
    }
    
    input {
        max-width: 159px;
    }
    
}
.info-hidden-2{
    display: none;
}
@media screen and (max-width: 625px){
    .outputs-div{
        display: none;
    }
    .outputs-hidden{
        width: 100vw;
        display: block;
    }
    .inputs{
        grid-template-columns: 1fr 1fr;
    }
    .calc-body{
        padding: 55px 5px 5px 5px;
    }
    .header{
        display: none;
    }
    .title{
        display: flex;
        padding: 1rem;
        justify-content: center;
        gap: 1rem;
        align-items: center;
    }
    .contents{
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
        justify-content: center;
    }
    .info-hidden-2{
        display: flex;
    }
    .info-box-2{
        display: none;
    }
    .remove-one{
        display: none;
    }
    .hidden-search-neighbor{
        display: grid;
        justify-content: center
    }

}
/**
 * Main wrapper
 */
 .select-search {
    max-width: 166px;
    position: relative;
    border: solid 1px rgb(48, 48, 48);
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
    text-decoration: none;
}

.select-search__value::after {
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 33px;
    width: 100%;
    padding: 0 40px 0 7px;
    background: rgb(255, 255, 255);
    border:solid 1px transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    outline: none;
    font-weight: bold;
    color: rgb(37, 37, 37);
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 33px;
    width: 100%;
    padding: 0 10px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #dfbe2b;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(204, 188, 47, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #d4b52a;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:focus {
    border-color: #000000;
    border-radius: 0px;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

